import React, { useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { LANG, LOCALE } from '../services/locales';
import { sendMessage } from '../services/axios';
import '../../styles/one-review.scss';

const OneReview = ({
    company,
    author,
    text,
    originalLink,
    emoji,
    isSecondary,
    textHeight,
    heightChangeFunc,
    prevClickFunc,
    nextClickFunc,
}) => {
    const [isAppear, setIsAppear] = useState(false);
    const [isDisappear, setIsDisappear] = useState(false);
    const block = useRef(null);
    const blockText = useRef(null);

    useLayoutEffect(() => {
        if (!isSecondary) {
            heightChangeFunc(block.current.clientHeight, blockText.current.clientHeight);
            setTimeout(() => setIsAppear(true), 1);
        } else {
            setTimeout(() => {
                setIsDisappear(true);
            }, 1);
        }
    }, [heightChangeFunc, isSecondary]);

    const getEmoji = () => require(`../../img/emoji/${emoji}.png`);

    const openOriginal = () => {
        const fullMessage = `Был открыт оригинал отзыва «${company}» - ${LANG}`;
        sendMessage(fullMessage);
    };

    return (
        <div
            className={`one-review
                ${isAppear ? ' one-review--appear' : ''}
                ${isSecondary && !isDisappear ? ' one-review--disappear' : ''}
            `}
            ref={block}
        >
            <div className="one-review--emoji" style={{ backgroundImage: `url(${getEmoji()}` }} />
            <div className="one-review--container">
                <div className="one-review--company-author">
                    <div className="one-review--company">{company}</div>
                    <div className="one-review--author-block">
                        <div className="one-review--author">{author.fio[LANG]}</div>
                        <div className="one-review--position">{author.position[LANG]}</div>
                    </div>
                </div>
                <div
                    className="one-review--description"
                    style={isSecondary ? { height: `${textHeight}px` } : null}
                    ref={blockText}
                >
                    {text}
                </div>
                <div className="one-review--controls">
                    <div className="one-review--prev-next">
                        <div className="one-review--prev" onClick={prevClickFunc} />
                        <div className="one-review--original">
                            <a
                                onClick={openOriginal}
                                href={`/${originalLink}`}
                                target="_blank"
                                rel="noreferrer"
                                className="btn btn--dark"
                            >
                                {LOCALE.blockReviews.getLetter[LANG]}
                            </a>
                        </div>
                        <div className="one-review--next" onClick={nextClickFunc} />
                    </div>
                    <div className="one-review--original">
                        <a
                            onClick={openOriginal}
                            href={`/${originalLink}`}
                            target="_blank"
                            rel="noreferrer"
                            className="btn btn--dark"
                        >
                            {LOCALE.blockReviews.getLetter[LANG]}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

OneReview.propTypes = {
    company: PropTypes.string,
    author: PropTypes.object,
    text: PropTypes.string,
    originalLink: PropTypes.string,
    emoji: PropTypes.string,
    textHeight: PropTypes.number,
    isSecondary: PropTypes.bool,
    heightChangeFunc: PropTypes.func,
    prevClickFunc: PropTypes.func,
    nextClickFunc: PropTypes.func,
};

export default OneReview;
