import React from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from '../store/globals/actions';
import iconLogo from '../../img/articles/article-01-belrose/icon-ros.png';
import iconTime from '../../img/icon-time.svg';
import iconMoney from '../../img/icon-price.svg';
import bgArticle1 from '../../img/articles/article-01-belrose/bg-article-1.jpg';
import bgArticle2 from '../../img/articles/article-01-belrose/bg-article-2.jpg';
import bgArticle3 from '../../img/articles/article-01-belrose/bg-article-3.jpg';
import bgArticle4 from '../../img/articles/article-01-belrose/bg-article-4.jpg';
import bgArticle5 from '../../img/articles/article-01-belrose/bg-article-5.jpg';
import bgArticle6 from '../../img/articles/article-01-belrose/bg-article-6.jpg';
import '../../styles/block-article.scss';

const BlockArticle = () => {
    const dispatch = useDispatch();

    const closeMdl = () => {
        dispatch(closeModal(null));
    };

    return (
        <div className="modal--overlay">
            <div className="modal--wrap">
                <div className="article">
                    <div className="close" onClick={closeMdl}>
                        <div className="icon-close-m" />
                    </div>
                    <div className="container">
                        <div className="article--headtitle">Промосайт “Ростелеком-туризм”</div>
                        <div className="article--target">
                            <div className="article--target__item">
                                <div
                                    className="article--target__icon"
                                    style={{
                                        backgroundImage: `url('${iconLogo}')`,
                                    }}
                                />
                                <div className="article--target__text">
                                    <span>Заказчик</span>
                                    <p>Rostelecom comunication</p>
                                </div>
                            </div>
                            <div className="article--target__item">
                                <div
                                    className="article--target__icon"
                                    style={{
                                        backgroundImage: `url('${iconTime}')`,
                                    }}
                                />
                                <div className="article--target__text">
                                    <span>Время время исполнения</span>
                                    <p>3 месяца</p>
                                </div>
                            </div>
                            <div className="article--target__item">
                                <div
                                    className="article--target__icon"
                                    style={{
                                        backgroundImage: `url('${iconMoney}')`,
                                    }}
                                />
                                <div className="article--target__text">
                                    <span>Сумма</span>
                                    <p>700 000р</p>
                                </div>
                            </div>
                        </div>
                        <img src={bgArticle1} alt="" />
                        <div className="article--task">
                            <div className="article--title">Задача</div>
                            <div className="article--task__info">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim esse ex magnam, odio
                                quidem repudiandae. Ab aliquam, corporis cupiditate doloremque excepturi incidunt nam
                                neque nesciunt odio provident quia quibusdam repellat vel? Debitis eligendi est
                                excepturi numquam pariatur. Consectetur debitis delectus dignissimos dolores eligendi
                                hic possimus tempora ut vel. Enim, pariatur? Lorem ipsum dolor sit amet, consectetur
                                adipisicing elit. Aut culpa cum dolores doloribus dolorum, eligendi eos et
                                exercitationem ipsam maxime molestias mollitia, nam pariatur sed veniam? Assumenda cum
                                dicta, fugiat iusto maiores natus nemo numquam vitae! Dolor maxime quaerat sapiente!
                            </div>
                        </div>
                        <img src={bgArticle2} alt="" />
                        <div className="article--idea">
                            <div className="article--title">Мозговой штурм</div>
                            <div className="article--idea__info">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi cum deleniti doloremque
                                earum illum iste nam pariatur placeat, quaerat quidem repellendus sed, sint soluta ut
                                voluptatum? Ad asperiores aut consequuntur, culpa cupiditate deleniti dicta dolorum ea
                                est exercitationem fugit id inventore ipsum iste labore laborum libero mollitia
                                necessitatibus neque nulla numquam odit pariatur porro praesentium quibusdam rem
                                repudiandae saepe sit ullam vel velit veritatis vero voluptate. Adipisci at eaque earum
                                ex ipsum necessitatibus possimus praesentium, quod similique veritatis? Ab aliquam
                                architecto consequuntur cum cupiditate dignissimos dolorem ducimus earum eos fuga fugit
                                harum id illo impedit in iste laboriosam maiores molestias nam, necessitatibus nesciunt
                                nihil nisi odio officiis omnis possimus provident quasi quidem quo repellendus sapiente
                                similique sint, soluta sunt vel veritatis voluptas? Adipisci alias animi deserunt
                                distinctio doloribus eaque ex illum in, maxime possimus quae similique! Deleniti eaque
                                eius error fuga illo inventore ipsam itaque laudantium libero obcaecati perspiciatis,
                                quaerat quam qui quibusdam quisquam reiciendis sit ut vel velit voluptatibus. Asperiores
                                doloribus eius enim excepturi fuga iusto, nemo nobis quidem ratione veritatis!
                                Accusantium, ad assumenda culpa deleniti doloribus esse, est fugiat ipsam ipsum, iste
                                iure iusto maiores minus modi non nulla perspiciatis sed tempora vel voluptates.
                                Assumenda inventore molestiae odit.
                            </div>
                        </div>
                        <img src={bgArticle3} alt="" />
                        <div className="article--brand">
                            <div className="article--title">Айдентика</div>
                            <div className="article--brand__info">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium amet aspernatur at
                                cupiditate delectus deleniti esse ipsum laborum maxime mollitia necessitatibus nobis
                                nulla praesentium quis quod repellat repellendus, sunt! Ad aliquam consectetur deserunt,
                                distinctio enim fugiat magnam nisi porro quae quos similique suscipit vero voluptatibus.
                                Dolores nesciunt repellat soluta ullam. Distinctio dolores ex impedit itaque labore quia
                                quis! Animi consequatur fugit repudiandae unde velit veritatis? Accusantium aspernatur
                                assumenda blanditiis consectetur consequatur, corporis culpa delectus distinctio error
                                et exercitationem, fuga harum incidunt ipsa iste nemo porro, quae quis quo voluptatibus?
                                Aspernatur cupiditate dolorem fugiat illo ipsam quod recusandae similique temporibus.
                                Ut?
                            </div>
                        </div>
                        <div className="article--img-three">
                            <img src={bgArticle4} alt="" />
                            <img src={bgArticle5} alt="" />
                            <img src={bgArticle6} alt="" />
                        </div>
                        <div className="article--result">
                            <div className="article--title">Итог</div>
                            <div className="article--result__box">
                                <div className="article--result__item">
                                    <span>456</span>
                                    <p>Часов затрачено на работу</p>
                                </div>
                                <div className="article--result__item">
                                    <span>+30%</span>
                                    <p>Ровно на столько выросла прибыль заказчика</p>
                                </div>
                                <div className="article--result__item">
                                    <span>15 000</span>
                                    <p>Новых клиентов удалось привлечь</p>
                                </div>
                            </div>
                        </div>
                        <div className="article--footer">
                            <div className="article--footer__name">Juice</div>
                            <div className="article--title">
                                У Вас есть
                                <br /> задание для нас?
                            </div>
                            <div className="article--subtitle">
                                Оставьте свою заявку или позвоните по одному из наших
                                <br /> телефонов. Мы ответим Вам в ближайщее время.
                            </div>
                            <div className="footer--contact">
                                <a href="mailto:i.grishina@vk-admin.com">i.grishina@vk-admin.com</a>
                                <a href="tel:74722232952">+7 (4722) 232–952</a>
                            </div>
                            <div className="footer--send">
                                <div className="footer--form">
                                    <input placeholder="Как вас зовут?" />
                                    <input placeholder="Перезвоните или напишите мне сюда:" />
                                    <textarea placeholder="Что мы можем сделать для Вас?" />
                                </div>
                                <div className="btn btn--send">Отправить</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlockArticle;
