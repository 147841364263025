import React from 'react';
import PropTypes from 'prop-types';
import { numberWithSpaces } from '../services/functions';

const BlockLittleItem = ({ number, text }) => {
    return (
        <div className="block-little--item">
            <div className="block-little--title">{numberWithSpaces(number)}</div>
            <div className="block-little--text">{text}</div>
        </div>
    );
};

BlockLittleItem.propTypes = {
    number: PropTypes.number,
    text: PropTypes.string,
};

export default BlockLittleItem;
