import React, { useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { scroller } from 'react-scroll';
import { LANG, LOCALE } from '../services/locales';
import {EMAIL, PHONE} from '../services/settings';
import { closeMenu } from '../store/globals/actions';
import { preparePhone } from '../services/functions';
import '../../styles/block-menu.scss';

const BlockMenu = () => {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(' show');

    useLayoutEffect(() => {
        // document.querySelector('.block-menu').style.paddingRight = `${getScrollWidth()}px`;
    }, []);

    const hideMenu = () => {
        dispatch(closeMenu(null));
    };

    const closeButton = () => {
        setShowModal('');
        hideMenu();
    };

    const scrollTo = element => {
        scroller.scrollTo(element, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
        });
    };

    const scrollToBlock = element => {
        hideMenu();
        scrollTo(element);
    };

    const getScrollWidth = () => {
        const div = document.createElement('div');

        div.style.width = '50px';
        div.style.height = '50px';
        div.style.overflowY = 'scroll';
        div.style.visibility = 'hidden';

        document.body.appendChild(div);

        const scrollWidth = div.offsetWidth - div.clientWidth;
        div.remove();

        return scrollWidth;
    };

    return (
        <div className={`block-menu${showModal}`}>
            <div className="menu--wrap">
                <div className="navbar">
                    <a href="/" className="logo" />
                    <div className="navbar--name" />
                    <div className="navbar--contact">
                        <a href={`tel:${PHONE}`}>{preparePhone(PHONE)}</a>
                    </div>
                    <div className="nav-menu" onClick={closeButton}>
                        <div className="icon-close" />
                    </div>
                </div>
                <div className="menu--content">
                    <div className="menu--box">
                        <div className="menu--item" onClick={() => scrollToBlock('block-can')}>
                            {LOCALE.blockMenu.know[LANG]}
                        </div>
                        <div className="menu--item" onClick={() => scrollToBlock('block-done')}>
                            {LOCALE.blockMenu.done[LANG]}
                        </div>
                        <div className="menu--item" onClick={() => scrollToBlock('block-number')}>
                            {LOCALE.blockMenu.numbers[LANG]}
                        </div>
                        <div className="menu--item" onClick={() => scrollToBlock('block-clients')}>
                            {LOCALE.blockMenu.clients[LANG]}
                        </div>
                        <div className="menu--item" onClick={() => scrollToBlock('block-contacts')}>
                            {LOCALE.blockMenu.contacts[LANG]}
                        </div>
                    </div>
                    <div className="menu--contact">
                        <a href={`tel:${PHONE}`}>{preparePhone(PHONE)}</a>
                    </div>
                    <div className="menu--address">
                        {LOCALE.footer.city[LANG]}, {LOCALE.footer.address[LANG]}
                        <span><a href={`mailto:${EMAIL}`}>{EMAIL}</a></span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlockMenu;
