import React from 'react';
import { Element } from 'react-scroll';
import BlockIntro from '../component/BlockIntro';
import BlockCan from '../component/BlockCan';
import BlockDone from '../component/BlockDone';
import BlockLittleNumber from '../component/BlockLittleNumber';
import BlockClients from '../component/BlockClients';
import Footer from '../component/Footer';
import BlockPresentation from '../component/BlockPresentation';
import BlockReviews from '../component/BlockReviews';
import ContactIcons from "../component/ContactIcons";

const PanelHome = () => {
    return (
        <>
            <BlockIntro />
            <Element name="block-can">
                <BlockCan />
            </Element>
            {/*<Element name="block-presentation">*/}
            {/*    <BlockPresentation />*/}
            {/*</Element>*/}
            <Element name="block-done">
                <BlockDone />
            </Element>
            <Element name="block-number">
                <BlockLittleNumber />
            </Element>
            <Element name="block-clients">
                <BlockClients />
            </Element>
            <Element name="block-reviews">
                <BlockReviews />
            </Element>
            <Element name="block-contacts">
                <Footer />
                <ContactIcons />
            </Element>
        </>
    );
};

export default PanelHome;
