import React from 'react';

export const LOCALE = {
    blockMenu: {
        know: {
            ru: 'Умеем',
            en: 'We can',
        },
        done: {
            ru: 'Сделано',
            en: 'Done',
        },
        numbers: {
            ru: 'Цифры',
            en: 'Numbers',
        },
        clients: {
            ru: 'Клиенты',
            en: 'Clients',
        },
        contacts: {
            ru: 'Контакты',
            en: 'Contacts',
        },
    },

    blockIntro: {
        title: {
            ru: 'Мы делаем бизнес удобнее',
            en: 'We make business more convenient',
        },
        introText: {
            ru:
                'Разрабатываем такие продукты, которые позволяют ' +
                'автоматизировать бизнес, увеличить прибыль, освободить время',
            en: 'We develop products that help to automate business, increase income and save your time.',
        },
        we: {
            ru: 'We`r Juice Development',
            en: 'We`r Juice Development',
        },
    },

    blockCan: {
        hereIsWhatDo: {
            ru: 'Вот что мы умеем',
            en: 'Here is what we do',
        },
        onlineShops: {
            ru: 'Интернет-магазины',
            en: 'E-Commerce',
        },
        crmSystems: {
            ru: 'CRM и ERP системы',
            en: 'CRM and EPR system',
        },
        landings: {
            ru: 'Лендинги',
            en: 'Landing pages',
        },
        mobileApps: {
            ru: 'Мобильные',
            en: 'Mobile Apps',
        },
        mobileApps2: {
            ru: 'приложения',
            en: '',
        },
        vkMiniApps: {
            ru: 'VK Mini Apps',
            en: 'VK Mini Apps',
        },
        webApps: {
            ru: 'Веб-приложения',
            en: 'Web-applications',
        },
    },

    blockPresentation: {
        title: {
            ru: 'Про нас в одном файле',
            en: 'Our presentation',
        },
        description: {
            ru:
                'Лучше один раз увидеть. В презентации собрана вся ' +
                'информация про нашу компанию, нашу команду и, конечно, разработанные нами продукты.',
            en:
                'Seeing is believing. The presentation contains ' +
                'all the information about our company, our team and products developed by us.',
        },
        download: {
            ru: 'Скачать презентацию',
            en: 'Download presentation',
        },
        link: {
            ru: 'media/presentation/ru/JuiceDevelopment.pdf',
            en: 'media/presentation/en/JuiceDevelopment.pdf',
        },
    },

    blockDone: {
        bestProjects: {
            ru: 'Лучшие проекты',
            en: 'Best projects',
        },
        alreadyCompleted: {
            ru: 'Уже сделано',
            en: 'Already done',
        },
    },

    blockDoneItemList: {
        done1: {
            ru: 'Интернет-магазин «Розы Белгорья»',
            en: 'Online shop “Rozy Belogor’ya”',
        },
        done2: {
            ru: 'VK Mini App «Академия футбола»',
            en: 'VK Mini App “Football Academy”',
        },
        done3: {
            ru: 'VK Mini App «ПДД 2020»',
            en: 'VK Mini App “Traffic rules 2020”',
        },
        done4: {
            ru: 'Реализация раздела «Подкасты» в приложении ВКонтакте',
            en: 'Implementation of the “Podcasts” section in the app of social network VKontakte',
        },
        done5: {
            ru: 'VK Mini App «3D-тур»',
            en: 'VK Mini App “3D Tour”',
        },
        done6: {
            ru: 'Генератор QR-кодов',
            en: 'QR codes generator',
        },
        done7: {
            ru: 'CRM-система для предприятия «Znak», специализирующегося на обжарке кофе',
            en: 'CRM system for “Znak” company specialised in roasting',
        },
        done8: {
            ru: 'VK Mini App для оплаты покупок на VK FEST',
            en: 'VK Mini App for VK FEST payments',
        },
        done9: {
            ru: 'CRM-система для менеджеров ВКонтакте',
            en: 'CRM system for VKontakte managers',
        },
        done10: {
            ru: 'VK Mini App «Showreel»',
            en: 'VK Mini App “Showreel”',
        },
        done11: {
            ru: 'Видеопрезентация ресторана «Riviera»',
            en: 'Video presentation for “Riviera” restaurant',
        },
        done12: {
            ru: 'CRM-система для рекламного агенства «Медиа Эскадра»',
            en: 'CRM system for an Advertising Agency “Media Escadra”',
        },
        done13: {
            ru: 'VK Mini App для «ЛитРес:»',
            en: 'VK Mini App for “LitRes:”',
        },
        done14: {
            ru: 'VK Mini App анкетирование для ОПРФ',
            en: 'VK Mini App for CCRF questionnaires',
        },
        done15: {
            ru: 'VK Mini App для «Битвы Блогеров 2019»',
            en: 'VK Mini App for “Bloggers Battle 2019”',
        },
        done16: {
            ru: 'VK Mini App «Бессмертный полк»',
            en: 'VK Mini App “Immortal Regiment”',
        },
        done17: {
            ru: 'CRM-система для продаже и публикации конкурсов «Бесплатных» пабликов ВКонтакте',
            en: 'CRM system for selling and publishing contests in the Ad Network community VKontakte',
        },
        done18: {
            ru: 'Биржа рекламы Тик-Ток',
            en: 'TikTok Ads Market',
        },
        done19: {
            ru: 'VK Mini App «Битва мемов»',
            en: 'VK Mini App “Memes Fight”',
        },
        done20: {
            ru: 'VK Mini App «Итоги Года»',
            en: 'VK Mini App “Results of the Year”',
        },
        done21: {
            ru: 'Лендинг для Итогов Года',
            en: 'Landing page for “Results of the Year”',
        },
        done22: {
            ru: 'VK Donut',
            en: 'VK Donut',
        },
        done23: {
            ru: 'VK Mini App «Краш-тест 14 февраля»',
            en: 'VK Mini App “Crush test 14th of February”',
        },
        done24: {
            ru: 'Лендинг Ахматова',
            en: 'Landing page for Anna Akhmatova',
        },
        done25: {
            ru: 'VK Mini App «Музейный Гид»',
            en: 'VK Mini App “Museum guide”',
        },
        done26: {
            ru: 'VK Mini App «Новогодние предсказания»',
            en: 'VK Mini App “New Year predictions”',
        },
        done27: {
            ru: 'VK Mini App «Синяя птица»',
            en: 'VK Mini App for TV Show “Blue Bird“',
        },
        done28: {
            ru: 'VK Mini App «Очень честные новости»',
            en: '',
        },
        done29: {
            ru: 'Эксперты ВКонтакте',
            en: 'VK Mini App “Experts Vkontakte”',
        },

        more: {
            ru: 'Еще',
            en: 'More',
        },
    },

    blockLittleNumber: {
        littleNumbers: {
            ru: 'Немного',
            en: 'Some',
        },
        littleNumbers2: {
            ru: 'цифр',
            en: 'numbers',
        },
        bottomText: {
            ru: 'Mail.ru и Вконтакте наши любимые клиенты',
            en: 'Mail.ru & VKontakte are our favorite clients',
        },
    },

    blockLittleItemList: {
        textAllProjects: {
            ru: 'проекта выполнено за 5 лет работы',
            en: 'projects done in 5 years of work',
        },
        textCoffee: {
            ru: 'кружек кофе выпито нашими сотрудниками',
            en: 'cups of coffee drank by our team members',
        },
        textCodeLines: {
            ru: 'строк кода написано',
            en: 'lines of code written',
        },
        textPurchases: {
            ru: 'покупок совершили клиенты самой крупной CRM',
            en: 'purchases made by the largest CRM clients',
        },
    },

    blockClients: {
        clients: {
            ru: 'Наши клиенты',
            en: 'Our clients',
        },

        mail: {
            ru: 'Mail.ru',
            en: 'Mail.ru',
        },
        vk: {
            ru: 'ВКонтакте',
            en: 'VKontakte',
        },
        sber: {
            ru: 'Сбербанк',
            en: 'Sberbank',
        },
        russia1: {
            ru: 'Телеканал Россия 1',
            en: 'Russia-1',
        },
        tnt: {
            ru: 'Телеканал ТНТ',
            en: 'TNT',
        },
        life: {
            ru: 'Life News',
            en: 'Life News',
        },
        avia: {
            ru: 'Авиасейлс',
            en: 'Aviasales',
        },
        litres: {
            ru: 'Литрес',
            en: 'LitRes',
        },
        free: {
            ru: 'Бесплатный',
            en: 'Besplatny',
        },
        palata: {
            ru: 'Общественная палата РФ',
            en: 'Civic Chamber of the Russian Federation',
        },
        mosru: {
            ru: 'Правительство Москвы',
            en: 'Government of Moscow',
        },
        memepedia: {
            ru: 'Memepedia',
            en: 'Memepedia',
        },
    },

    blockClientsItemList: {
        textFriends: {
            ru: 'Вы можете стать нашим другом уже сегодня',
            en: 'You can become our friend today',
        },
    },

    blockReviews: {
        title: {
            ru: 'отзывы',
            en: 'reviews',
        },
        getLetter: {
            ru: 'Оригинал письма',
            en: 'Original letter',
        },
    },

    blockReviewsItemList: {
        reviews: [
            {
                company: {
                    ru: 'Авиасейлс',
                    en: 'Aviasales',
                },
                author: {
                    fio: {
                        ru: 'Полина Гавра',
                        en: 'Pauline Gavra',
                    },
                    position: {
                        ru: 'Директор по персоналу',
                        en: 'HR Director',
                    },
                },
                description: {
                    ru: (
                        <>
                            <p>
                                Выражаю благодарность коллективу разработчиков проектов «Перенеси всех» и «Front hiring:
                                Изи мод» от лица компании Авиасейлс (Go Travel Un Limited). Во время работы команда
                                продемонстрировала ответственный подход к решению задач, а также оперативную реакцию на
                                дополнения и доработки систем.
                            </p>
                            <p>
                                Можем рекомендовать ООО «Джус Девелопмент» как надежного поставщика программного
                                обеспечения.
                            </p>
                        </>
                    ),
                    en: (
                        <>
                            <p>
                                I express my gratitude to the team of developers of the projects "Transfer everyone" and
                                "Front hiring: Easy mod" on behalf of the Aviasales company (Go Travel Un Limited).
                                During the working process, the team demonstrated a responsible approach to solving
                                problems, as well as a prompt response to additions and improvements to the systems.
                            </p>
                            <p>We can recommend Juice Development LLC as a reliable software supplier.</p>
                        </>
                    ),
                },
                letter: {
                    ru: 'media/letter/Aviasales_thanks.pdf',
                    en: 'media/letter/Aviasales_thanks.pdf',
                },
                emoji: {
                    ru: 'airplane',
                    en: 'airplane',
                },
            },
            {
                company: {
                    ru: 'Телеканал ТНТ',
                    en: 'TNT TV channel',
                },
                author: {
                    fio: {
                        ru: 'Анастасия Попова',
                        en: 'Anastasia Popova',
                    },
                    position: {
                        ru: 'Руководитель СММ-отдела',
                        en: 'Head of the SMM Department',
                    },
                },
                description: {
                    ru: (
                        <>
                            <p>
                                Выражаю благодарность коллективу разработчиков за квалифицированную подготовку проекта в
                                поддержку нового сезона сериала «Света с того света» в социальной сети ВКонтакте, а
                                также за оперативное и качественное решение поставленных задач.
                            </p>
                            <p>
                                От лица команды ТНТ желаю вам дальнейшего развития и реализации новых, интересных
                                проектов. Надеемся на плодотворное сотрудничество в будущем.
                            </p>
                            <p>
                                Можем рекомендовать ООО «Джус Девелопмент» как надежного поставщика программного
                                обеспечения.
                            </p>
                        </>
                    ),
                    en: (
                        <>
                            <p>
                                I express my gratitude to the team of developers for the qualified preparation of the
                                project in support of the new season of the TV series "Sveta from the other World" in
                                the social network VKontakte, as well as for the prompt and high-quality solution of the
                                tasks set.
                            </p>
                            <p>
                                On behalf of the TNT team, I wish you further development and implementation of new
                                interesting projects. We hope for fruitful cooperation in the future.
                            </p>
                            <p>We can recommend Juice Development LLC as a reliable software supplier.</p>
                        </>
                    ),
                },
                letter: {
                    ru: 'media/letter/TNT_thanks.pdf',
                    en: 'media/letter/TNT_thanks.pdf',
                },
                emoji: {
                    ru: 'television',
                    en: 'television',
                },
            },
            {
                company: {
                    ru: 'Life',
                    en: 'Life',
                },
                author: {
                    fio: {
                        ru: 'Анатолий Сулейманов',
                        en: 'Anatoly Suleymanov',
                    },
                    position: {
                        ru: 'Первый заместитель генерального директора',
                        en: 'First Deputy General Director',
                    },
                },
                description: {
                    ru: (
                        <>
                            <p>
                                Выражаю благодарность коллективу разработчиков CRM-системы «Файв паблик» от лица
                                компании «Ньюс Медиа». Желаем Вам дальнейшего развития и успехов. Надеемся на дальнейшее
                                плодотворное сотрудничество.
                            </p>
                            <p>
                                Отдельную благодарность выражаю Папкову Игорю Андреевичу за качественный продукт,
                                произведенный точно в срок. Ускоренный темп разработки в связи со срочностью проекта,
                                ответственный подход к работе с конфиденциальными данными и качественному обеспечению
                                безопасности проекта.
                            </p>
                            <p>
                                Можем рекомендовать компанию ООО «Джус Девелопмент», как надежного поставщика
                                программного обеспечения и CRM-систем для бизнеса.
                            </p>
                        </>
                    ),
                    en: (
                        <>
                            <p>
                                I express my gratitude to the team of developers of the CRM system "Five Public" on
                                behalf of the company "News Media". We wish you further development and success. We hope
                                for further fruitful cooperation.
                            </p>
                            <p>
                                I would like to express my special gratitude to Igor Andreevich Papkov for a
                                high-quality product produced on time. Accelerated pace of development due to the
                                urgency of the project, a responsible approach to working with confidential data and
                                high-quality project security.
                            </p>
                            <p>
                                We can recommend the company "Juice Development" LLC as a reliable supplier of software
                                and CRM systems for business.
                            </p>
                        </>
                    ),
                },
                letter: {
                    ru: 'media/letter/Life_thanks.pdf',
                    en: 'media/letter/Life_thanks.pdf',
                },
                emoji: {
                    ru: 'newspaper',
                    en: 'newspaper',
                },
            },
            {
                company: {
                    ru: 'Ривьера',
                    en: 'Riviera',
                },
                author: {
                    fio: {
                        ru: 'Лина Зотова',
                        en: 'Lina Zotova',
                    },
                    position: {
                        ru: 'Исполнительный директор',
                        en: 'Executive Director',
                    },
                },
                description: {
                    ru: (
                        <>
                            <p>
                                Выражаю благодарность коллективу компании «Джус Девелопмент» от лица компании «Ривьера».
                                Желаем Вам дальнейшего развития и успехов. Надеемся на дальнейшее плодотворное
                                сотрудничество.
                            </p>
                            <p>
                                Отдельную благодарность выражаю генеральному директору Папкову Игорю Андреевичу за
                                ответственный подход к работе.
                            </p>
                            <p>Могу с уверенностью рекомендовать компанию ООО «Джус Девелопмент» к сотрудничеству.</p>
                        </>
                    ),
                    en: (
                        <>
                            <p>
                                I express my gratitude to the staff of the Juice Development company on behalf of the
                                Riviera company. We wish you further development and success. We hope for further
                                fruitful cooperation.
                            </p>
                            <p>
                                I would like to express my special gratitude to Igor Andreevich Papkov, General
                                Director, for his responsible approach to work.
                            </p>
                            <p>I can confidently recommend the company "Juice Development" LLC for cooperation.</p>
                        </>
                    ),
                },
                letter: {
                    ru: 'media/letter/Riviera_thanks.pdf',
                    en: 'media/letter/Riviera_thanks.pdf',
                },
                emoji: {
                    ru: 'water-wave',
                    en: 'water-wave',
                },
            },
            {
                company: {
                    ru: 'Изовол Агро',
                    en: 'Izovol Agro',
                },
                author: {
                    fio: {
                        ru: 'Кристина Сердюкова',
                        en: 'Kristina Serdyukova',
                    },
                    position: {
                        ru: 'Ведущий маркетолог',
                        en: 'Leading Marketer',
                    },
                },
                description: {
                    ru: (
                        <>
                            <p>
                                От лица компании «Изовол Агро» выражаю глубокую признательность всему коллективу ООО
                                «Джус Девелопмент» за проделанную работу и плодотворное сотрудничество. Поставив задачу
                                по созданию «продающего» сайта, на выходе мы получили качественный продукт для ведения
                                электронной коммерции. Разработанная вами CRM-система дала нам возможность заниматься
                                сбором и обработкой целевой клиентской базы, а также генерировать её в общую базу данных
                                с целью использования данной информации для решения текущих и стратегических
                                маркетинговых задач.
                            </p>
                            <p>
                                Хотелось бы отдельно поблагодарить Папкова Игоря Андреевича за ответственный подход к
                                работе с конфиденциальной информацией и качественный сервис.
                            </p>
                            <p>
                                Могу с уверенностью рекомендовать компанию ООО «Джус Девелопмент», как надежного
                                поставщика программного обеспечения.
                            </p>
                        </>
                    ),
                    en: (
                        <>
                            <p>
                                On behalf of Izovol Agro, I express my deep gratitude to the entire team of Juice
                                Development LLC for the work done and fruitful cooperation. Having set the task of
                                creating a "selling" site, we got a high-quality product for e-commerce. The CRM system
                                developed by you gave us the opportunity to collect and process the target customer
                                base, as well as generate it into a common database in order to use this information to
                                solve current and strategic marketing tasks.
                            </p>
                            <p>
                                I would like to thank Igor Andreevich Papkov separately for his responsible approach to
                                working with confidential information and high-quality service.
                            </p>
                            <p>
                                I can confidently recommend the company "Juice Development" LLC as a reliable software
                                supplier.
                            </p>
                        </>
                    ),
                },
                letter: {
                    ru: 'media/letter/Izovol_Agro_thanks.pdf',
                    en: 'media/letter/Izovol_Agro_thanks.pdf',
                },
                emoji: {
                    ru: 'rose',
                    en: 'rose',
                },
            },
        ],
    },

    footer: {
        textRequest: {
            ru: 'Оставьте свою заявку и мы свяжемся с вами в самое ближайшее время',
            en: 'Leave your request below and we will contact you shortly',
        },
        textCallMeBack: {
            ru: 'Перезвоните или напишите мне сюда:',
            en: 'Call me or text me here:',
        },
        whatCanWeDo: {
            ru: 'Что мы можем сделать для вас?',
            en: 'What can we do for you?',
        },
        buttonSend: {
            ru: 'Отправить',
            en: 'Send',
        },
        name: {
            ru: 'Как вас зовут?',
            en: 'What is your name?',
        },
        city: {
            ru: 'Белгород',
            en: 'Russia',
        },
        address: {
            ru: 'Садовая 3A, оф. 112A',
            en: 'Belgorod, Sadovaya 3A, 112A',
        },
        rekvizit: {
            ru: (
                <>
                    <div>ООО «ДЖУС ДЕВЕЛОПМЕНТ»</div>
                    <div>ОГРН 1173123001006</div>
                    <div>ИНН 3123404506</div>
                    <div>КПП 312301001</div>
                </>
            ),
            en: (
                <>
                    <div>LLC JUICE DEVELOPMENT</div>
                    <div>OGRN: 1173123001006</div>
                    <div>INN/KIO: 3123404506/312301001</div>
                    <div>Account: 40702840000000017541</div>
                    <div>SWIFT: RZBMRUMM</div>
                </>
            ),
        },
    },

    modalSuccess: {
        thanks: {
            ru: 'Спасибо!',
            en: 'Thank you!',
        },
        text1: {
            ru: 'Ваша заявка успешно отправлена.',
            en: 'Your request has been sent successfully.',
        },
        text2: {
            ru: 'В ближайшее время мы с Вами свяжемся.',
            en: 'We will contact you shortly.',
        },
        close: {
            ru: 'Закрыть',
            en: 'Close',
        },
    },
};

const getLang = () => {
    if (window.location.hostname.match(/.org/iu)) {
        return 'en';
    }

    return 'ru';
};

export const LANG = getLang();
