import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LANG, LOCALE } from '../services/locales';
import { openModal, openNextProjectPage } from '../store/globals/actions';
import BlockDoneItem from './BlockDoneItem';
import BlockArticle from './BlockArticle';
import done1 from '../../img/projects/project-01-belrose.jpg';
import done2 from '../../img/projects/project-02-footbal.jpg';
import done3 from '../../img/projects/project-03-pdd.jpg';
import done4 from '../../img/projects/project-04-podcasts.jpg';
import done5 from '../../img/projects/project-05-3d-tour.jpg';
import done6 from '../../img/projects/project-06-qr.jpg';
import done7 from '../../img/projects/project-07-znak.jpg';
import done8 from '../../img/projects/project-08-vk-fest.jpg';
import done9 from '../../img/projects/project-09-vk-crm.jpg';
import done10 from '../../img/projects/project-10-showreel.jpg';
import done11 from '../../img/projects/project-11-riviera.jpg';
import done12 from '../../img/projects/project-12-media-escadra.jpg';
import done13 from '../../img/projects/project-13-litres.jpg';
import done14 from '../../img/projects/project-14-zozh.jpg';
import done15 from '../../img/projects/project-15-blogers-battle.jpg';
import done16 from '../../img/projects/project-16-9-may.jpg';
import done17 from '../../img/projects/project-17-freepost.jpg';
import done18 from '../../img/projects/project-18-tiktok.jpg';
import done19 from '../../img/projects/project-19-membuttle.jpg';
import done20 from '../../img/projects/project-20-year-report.jpg';
import done21 from '../../img/projects/project-21-year-report-site.jpg';
import done22 from '../../img/projects/project-22-vk-donut.jpg';
import done23 from '../../img/projects/project-23-crush-test.jpg';
import done24 from '../../img/projects/project-24-landing-ahmatova.jpg';
import done25 from '../../img/projects/project-25-museums.jpg';
import done26 from '../../img/projects/project-26-ny-predictions.jpg';
import done27 from '../../img/projects/project-27-bluebird.jpg';
import done28 from '../../img/projects/project-28-fake-news.jpg';
import done29 from '../../img/projects/project-29-experts.jpg';

const BlockDoneItemList = () => {
    const dispatch = useDispatch();
    const showProjectsNumber = useSelector(state => state.globals.showProjectsNumber);

    const openNext = () => {
        dispatch(openNextProjectPage());
    };

    const openPromo = () => {
        dispatch(openModal(<BlockArticle />));
    };

    const projectsArray = [
        <BlockDoneItem image={done18} name={LOCALE.blockDoneItemList.done18[LANG]} />,
        <BlockDoneItem image={done19} name={LOCALE.blockDoneItemList.done19[LANG]} />,
        <BlockDoneItem image={done20} name={LOCALE.blockDoneItemList.done20[LANG]} />,
        <BlockDoneItem image={done21} name={LOCALE.blockDoneItemList.done21[LANG]} />,
        <BlockDoneItem image={done22} name={LOCALE.blockDoneItemList.done22[LANG]} />,
        <BlockDoneItem image={done23} name={LOCALE.blockDoneItemList.done23[LANG]} />,
        <BlockDoneItem image={done25} name={LOCALE.blockDoneItemList.done25[LANG]} />,
        <BlockDoneItem image={done26} name={LOCALE.blockDoneItemList.done26[LANG]} />,
        <BlockDoneItem image={done28} name={LOCALE.blockDoneItemList.done28[LANG]} />,
        <BlockDoneItem image={done29} name={LOCALE.blockDoneItemList.done29[LANG]} />,
        <BlockDoneItem image={done24} name={LOCALE.blockDoneItemList.done24[LANG]} />,
        <BlockDoneItem image={done27} name={LOCALE.blockDoneItemList.done27[LANG]} />,
        <BlockDoneItem image={done17} name={LOCALE.blockDoneItemList.done17[LANG]} />,
        <BlockDoneItem image={done16} name={LOCALE.blockDoneItemList.done16[LANG]} />,
        <BlockDoneItem image={done4} name={LOCALE.blockDoneItemList.done4[LANG]} />,
        <BlockDoneItem image={done3} name={LOCALE.blockDoneItemList.done3[LANG]} />,
        <BlockDoneItem image={done6} name={LOCALE.blockDoneItemList.done6[LANG]} />,
        <BlockDoneItem image={done8} name={LOCALE.blockDoneItemList.done8[LANG]} />,
        <BlockDoneItem image={done9} name={LOCALE.blockDoneItemList.done9[LANG]} />,
        <BlockDoneItem image={done13} name={LOCALE.blockDoneItemList.done13[LANG]} />,
        <BlockDoneItem image={done7} name={LOCALE.blockDoneItemList.done7[LANG]} />,
        <BlockDoneItem image={done2} name={LOCALE.blockDoneItemList.done2[LANG]} />,
        <BlockDoneItem image={done12} name={LOCALE.blockDoneItemList.done12[LANG]} />,
        <BlockDoneItem image={done5} name={LOCALE.blockDoneItemList.done5[LANG]} />,
        <BlockDoneItem image={done11} name={LOCALE.blockDoneItemList.done11[LANG]} />,
        <BlockDoneItem image={done1} name={LOCALE.blockDoneItemList.done1[LANG]} />,
        <BlockDoneItem image={done10} name={LOCALE.blockDoneItemList.done10[LANG]} />,
        <BlockDoneItem image={done14} name={LOCALE.blockDoneItemList.done14[LANG]} />,
        <BlockDoneItem image={done15} name={LOCALE.blockDoneItemList.done15[LANG]} />,
    ];

    const renderList = () => projectsArray.slice(0, showProjectsNumber);

    const renderMore = () => (
        <a onClick={openNext} className="btn btn--dark">
            {LOCALE.blockDoneItemList.more[LANG]}
        </a>
    );

    return (
        <>
            <div className="block-done--box">{renderList()}</div>
            <div className="block-done--bottom">
                {showProjectsNumber < projectsArray.length && renderMore()}
                {showProjectsNumber >= projectsArray.length && <div />}
                <div className="block-done--number">
                    <div className="block-done--number">
                        {Math.min(showProjectsNumber, projectsArray.length)} / {projectsArray.length}
                    </div>
                </div>
            </div>
        </>
    );
};

export default BlockDoneItemList;
