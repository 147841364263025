import React from 'react';
import { LANG, LOCALE } from '../services/locales';
import BlockClientsItem from './BlockClientsItem';
import vk from '../../img/clients/logo-vk.svg';
import mail from '../../img/clients/logo-mail.svg';
import lifeNews from '../../img/clients/logo-life-news.png';
import sber from '../../img/clients/logo-sber.svg';
import oprf from '../../img/clients/logo-oprf.png';
import freepost from '../../img/clients/logo-freepost.svg';
import litres from '../../img/clients/logo-litres.png';
import mosRu from '../../img/clients/logo-mos-ru.png';
import russia1 from '../../img/clients/logo-russia-1.png';
import tnt from '../../img/clients/logo-tnt.png';
import aviasales from '../../img/clients/logo-aviasales.png';
import memepedia from '../../img/clients/logo-memepedia.png';

const BlockClientsItemList = () => {
    const renderList = () => {
        return (
            <>
                <BlockClientsItem image={mail} name={LOCALE.blockClients.mail[LANG]} />
                <BlockClientsItem image={vk} name={LOCALE.blockClients.vk[LANG]} />
                <BlockClientsItem image={sber} name={LOCALE.blockClients.sber[LANG]} />
                <BlockClientsItem image={russia1} name={LOCALE.blockClients.russia1[LANG]} />
                <BlockClientsItem image={tnt} name={LOCALE.blockClients.tnt[LANG]} />
                <BlockClientsItem image={lifeNews} name={LOCALE.blockClients.life[LANG]} />
                <BlockClientsItem image={aviasales} name={LOCALE.blockClients.avia[LANG]} />
                <BlockClientsItem image={litres} name={LOCALE.blockClients.litres[LANG]} />
                <BlockClientsItem image={freepost} name={LOCALE.blockClients.free[LANG]} />
                <BlockClientsItem image={oprf} name={LOCALE.blockClients.palata[LANG]} />
                <BlockClientsItem image={mosRu} name={LOCALE.blockClients.mosru[LANG]} />
                <BlockClientsItem image={memepedia} name={LOCALE.blockClients.memepedia[LANG]} />
            </>
        );
    };

    return (
        <div className="block-clients--box">
            <div className="block-clients--item block-clients--item__empty">
                <div className="block-clients--item__wrapp">
                    <div className="block-clients--inn">{LOCALE.blockClientsItemList.textFriends[LANG]}</div>
                </div>
            </div>
            <div className="block-clients--item block-clients--item__empty">
                <div className="block-clients--item__wrapp" />
            </div>
            {renderList()}
        </div>
    );
};

export default BlockClientsItemList;
