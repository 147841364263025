import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { LANG, LOCALE } from '../services/locales';
import { EMAIL, PHONE } from '../services/settings';
import { sendMessage } from '../services/axios';
import { openModal } from '../store/globals/actions';
import { preparePhone } from '../services/functions';
import ModalSendSuccess from './ModalSendSuccess';
import '../../styles/footer.scss';

const Footer = () => {
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [contact, setContact] = useState('');
    const [isContactError, setIsContactError] = useState(false);

    const sendLeedMessage = () => {
        if (contact !== '') {
            dispatch(openModal(<ModalSendSuccess />));

            const formattedName = `Имя:\n\r${name}`;
            const formattedContact = `Контакты:\n\r${contact}`;
            const formattedMessage = `Сообщение:\n\r${message}`;
            const fullMessage = `----------\n\r${name !== '' ? `${formattedName}\n\r\n\r` : ''}${formattedContact}${
                message !== '' ? `\n\r\n\r${formattedMessage}` : ''
            }\n\r----------`;

            sendMessage(fullMessage);

            setName('');
            setMessage('');
            setContact('');
        } else {
            setIsContactError(true);
        }
    };

    const updateContact = e => {
        setContact(e.target.value);
        setIsContactError(false);
    };

    return (
        <div className="footer">
            <div className="container">
                <div className="footer--head">
                    <div className="footer--name">
                        Juice <span />
                    </div>
                    <div className="footer--address">
                        <p>{LOCALE.footer.textRequest[LANG]}</p>
                        <span>
                            {LOCALE.footer.city[LANG]}
                            <br />
                            {LOCALE.footer.address[LANG]}
                        </span>
                    </div>
                </div>
                <div className="footer--copy">2017 - {new Date().getFullYear()}</div>
                <div className="footer--bottom">
                    <div className="footer--contact">
                        <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
                        <a href={`tel:${PHONE}`}>{preparePhone(PHONE)}</a>
                    </div>
                    <div className="footer--send">
                        <div className="footer--form">
                            <input
                                placeholder={LOCALE.footer.name[LANG]}
                                onChange={e => setName(e.target.value)}
                                value={name}
                            />
                            <input
                                placeholder={LOCALE.footer.textCallMeBack[LANG]}
                                onChange={e => updateContact(e)}
                                value={contact}
                                className={isContactError ? 'contact-error' : ''}
                            />
                            <textarea
                                placeholder={LOCALE.footer.whatCanWeDo[LANG]}
                                onChange={e => setMessage(e.target.value)}
                                value={message}
                            />
                        </div>
                        <div className="btn btn--send" onClick={sendLeedMessage}>
                            {LOCALE.footer.buttonSend[LANG]}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
