import * as types from './actionTypes';

export const openModal = modal => ({
    type: types.OPEN_MODAL,
    modal,
});

export const closeModal = () => ({
    type: types.CLOSE_MODAL,
});

export const openMenu = menu => ({
    type: types.OPEN_MENU,
    menu,
});

export const closeMenu = () => ({
    type: types.CLOSE_MENU,
});

export const openNextProjectPage = () => ({
    type: types.OPEN_NEXT_PROJECT_PAGE,
});
