import React from 'react';
import { LANG, LOCALE } from '../services/locales';
import '../../styles/contact-icons.scss';

const ContactIcons = () => {
    return (
        <div className="contact-icons">
            <div className="contact-icons__block">
                <a href="https://vk.com/dev_juice" target="_blank" rel="noreferrer" className="contact-icons__vk" />
                <a href="https://t.me/juice_development" target="_blank" rel="noreferrer" className="contact-icons__tg" />
            </div>
            <div className="contact-icons__rekvizit">{LOCALE.footer.rekvizit[LANG]}</div>
        </div>
    );
};

export default ContactIcons;
