import React from 'react';
import { LANG, LOCALE } from '../services/locales';
import BlockLittleItemList from './BlockLittleItemList';
import '../../styles/block-little.scss';

const BlockLittleNumber = () => {
    return (
        <div className="block-little">
            <div className="container">
                <h1 className="block-title">
                    <span>{LOCALE.blockLittleNumber.littleNumbers[LANG]}</span>
                    <br className="x-none" />
                    {LOCALE.blockLittleNumber.littleNumbers2[LANG]}
                </h1>
                <div className="block-little--content">
                    <BlockLittleItemList />
                    <div className="block-little--j">J</div>
                </div>
                <div className="block-little--bottom">
                    <div>
                        <a href="https://vk.com/" target="_blank" rel="noopener noreferrer" className="icon-vk" />
                        <a href="https://mail.ru/" target="_blank" rel="noopener noreferrer" className="icon-mail" />
                    </div>
                    <span>{LOCALE.blockLittleNumber.bottomText[LANG]}</span>
                </div>
            </div>
        </div>
    );
};

export default BlockLittleNumber;
