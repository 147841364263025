import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PanelHome from './js/panel/PanelHome';
import './styles/style.scss';
import './styles/media.scss';

const App = props => {
    const { pageId, modal, menu } = props;
    let panel = '';

    switch (pageId) {
        case 'home':
            panel = <PanelHome />;
            break;

        default:
            panel = <PanelHome />;
            break;
    }

    return (
        <>
            {panel}
            {modal}
            {menu}
        </>
    );
};

App.propTypes = {
    pageId: PropTypes.string,
    modal: PropTypes.node,
    menu: PropTypes.node,
};

function mapStateToProps(state) {
    return {
        history: state.globals.history,
        modal: state.globals.modal,
        menu: state.globals.menu,
    };
}

export default connect(mapStateToProps, null)(App);
