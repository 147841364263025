import * as types from './actionTypes';

const PROJECT_PER_PAGE = 6;

const initialState = {
    modal: '',
    link: '',
    tabIndex: 1,
    subTabIndex: 1,
    menu: '',
    showProjectsNumber: PROJECT_PER_PAGE,
};

export const globals = (state = initialState, action) => {
    switch (action.type) {
        case types.OPEN_MODAL:
            document.body.classList.add('modal--open');
            return {
                ...state,
                modal: action.modal,
            };

        case types.CLOSE_MODAL:
            document.body.classList.remove('modal--open');
            return {
                ...state,
                modal: null,
            };

        case types.OPEN_MENU:
            document.body.classList.add('menu--open');
            return {
                ...state,
                menu: action.menu,
            };

        case types.CLOSE_MENU:
            document.body.classList.remove('menu--open');
            return {
                ...state,
                menu: null,
            };

        case types.OPEN_NEXT_PROJECT_PAGE:
            return {
                ...state,
                showProjectsNumber: state.showProjectsNumber + PROJECT_PER_PAGE,
            };

        default:
            return state;
    }
};
