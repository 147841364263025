import React from 'react';
import PropTypes from 'prop-types';

const BlockClientsItem = ({ image, name }) => {
    return (
        <div className="block-clients--item">
            <div className="block-clients--item__wrapp">
                <div className="block-clients--inn">
                    <div
                        className="block-clients--image"
                        style={{
                            backgroundImage: `url('${image}')`,
                        }}
                    />
                    <div className="block-clients--name">{name}</div>
                </div>
            </div>
        </div>
    );
};

BlockClientsItem.propTypes = {
    image: PropTypes.string,
    name: PropTypes.string,
};

export default BlockClientsItem;
