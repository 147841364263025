// Падеж
export const wordPad = (num, t, ta, tov) => {
    num %= 100;
    if (num % 10 === 1 && (num < 10 || num > 20)) {
        return t;
    }
    if (num % 10 >= 2 && num % 10 <= 4 && (num < 10 || num > 20)) {
        return ta;
    }
    return tov;
};

// Нули для больших чисел
export const numberWithSpaces = x => {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    return parts.join('.');
};

// Преобразовать телефон
export const preparePhone = phone => `+7 (${phone[1]}${phone[2]}${phone[3]}) ${phone[4]}${phone[5]}${phone[6]}-${phone[7]}${phone[8]}-${phone[9]}${phone[10]}`;
