import axios from 'axios';

const API_URL = 'https://staging.minitask.vk-admin.com/';

export const sendMessage = message => {
    return axiosRequest('new-juice-request', { message });
};

export const axiosRequest = async (url, postData = {}) => {
    const successFunc = response => {
        if (response.data.result === 'success') {
            return response.data;
        } else {
            return { result: 'fail', error: response.data.error !== undefined ? response.data.error : 'unknown' };
        }
    };

    const failFunc = () => {
        return { result: 'fail', error: 'unknown' };
    };

    // Подготавливаем параметры
    const preparedPostData = new FormData();
    Object.keys(postData).forEach(key => {
        preparedPostData.append(key, postData[key]);
    });

    // Делаем запрос
    return axios({
        method: 'post',
        url: `${API_URL}${url}`,
        data: preparedPostData,
        crossDomain: true,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    })
        .then(result => successFunc(result))
        .catch(error => failFunc(error));
};
