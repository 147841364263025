import React from 'react';
import { useDispatch } from 'react-redux';
import { LANG, LOCALE } from '../services/locales';
import { closeModal } from '../store/globals/actions';
import Modal from './Modal';

const ModalSendSuccess = () => {
    const dispatch = useDispatch();

    const closeModalClick = () => {
        dispatch(closeModal());
    };

    return (
        <Modal
            id="add-panel"
            title={LOCALE.modalSuccess.thanks[LANG]}
            width={500}
            bodyBottom={24}
            rightButtons={[
                <div className="btn btn--send" onClick={closeModalClick}>
                    {LOCALE.modalSuccess.close[LANG]}
                </div>,
            ]}
        >
            {LOCALE.modalSuccess.text1[LANG]}
            <br />
            {LOCALE.modalSuccess.text2[LANG]}
        </Modal>
    );
};

export default ModalSendSuccess;
