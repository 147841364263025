import React from 'react';
import { useDispatch } from 'react-redux';
import { LANG, LOCALE } from '../services/locales';
import { PHONE } from '../services/settings';
import { openMenu } from '../store/globals/actions';
import { preparePhone } from '../services/functions';
import Parallax from './Parallax';
import BlockMenu from './BlockMenu';
import parallax1 from '../../img/parallax/parallax_1.png';
import parallax2 from '../../img/parallax/parallax_2.png';
import parallax3 from '../../img/parallax/parallax_3.png';
import parallax4 from '../../img/parallax/parallax_4.png';
import '../../styles/header.scss';
import '../../styles/block-intro.scss';

const BlockIntro = () => {
    const dispatch = useDispatch();

    const showMenu = () => {
        dispatch(openMenu(<BlockMenu />));
    };

    return (
        <div className="block-intro">
            <div className="juice-name">Juice</div>
            <Parallax image={parallax1} size={65} top={360} left={90} />
            <Parallax image={parallax2} size={65} top={380} right={90} />
            <Parallax image={parallax3} size={130} top={550} left={-30} />
            <Parallax image={parallax4} size={160} top={-30} right={10} />
            <div className="header">
                <div className="container">
                    <div className="navbar">
                        <a href="/" className="logo" />
                        <div className="navbar--contact">
                            <a className="phone" href={`tel:${PHONE}`}>
                                {preparePhone(PHONE)}
                            </a>
                        </div>
                        <div className="nav-menu" onClick={showMenu}>
                            <div className="icon-menu" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="block-intro--box">
                    <h1 className="block-intro--title">{LOCALE.blockIntro.title[LANG]}</h1>
                    <div className="block-intro--text">{LOCALE.blockIntro.introText[LANG]}</div>
                </div>
                <div className="block-intro--we">{LOCALE.blockIntro.we[LANG]}</div>
                <div className="block-intro--image" />
            </div>
        </div>
    );
};

export default BlockIntro;
