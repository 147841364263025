import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import { Route, Router } from 'react-router';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import { rootReducer } from './js/store/reducers';
import App from './App';

const history = createBrowserHistory();

const logger = store => next => action => {
    if (process.env.NODE_ENV === 'development') {
        console.log('dispatching', action);
    }
    return next(action);
};

export const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk, routerMiddleware(history), logger)),
);

const urls = ['home'];

const pathRegex = `/:pageId(${urls.join('|')}|)?`;

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <Route path={pathRegex} component={props => <App pageId={props.match.params.pageId} />} />
        </Router>
    </Provider>,
    document.getElementById('root'),
);
