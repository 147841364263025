import React from 'react';
import { LANG, LOCALE } from '../services/locales';
import BlockClientsItemList from './BlockClientsItemList';
import '../../styles/block-clients.scss';

const BlockClients = () => {
    return (
        <div className="block-clients">
            <div className="container">
                <h1 className="block-title">{LOCALE.blockClients.clients[LANG]}</h1>
                <BlockClientsItemList />
            </div>
        </div>
    );
};

export default BlockClients;
