import React from 'react';
import { LANG, LOCALE } from '../services/locales';
import '../../styles/block-can.scss';

const BlockCan = () => {
    return (
        <div className="block-can">
            <div className="container">
                <div className="block-can--side-text">{LOCALE.blockCan.hereIsWhatDo[LANG]}</div>
                <div className="block-can--box">
                    <div className="block-can--item">
                        <div className="block-can--icon icon--cms" />
                        <div className="block-can--text">{LOCALE.blockCan.onlineShops[LANG]}</div>
                    </div>
                    <div className="block-can--item">
                        <div className="block-can--icon icon--crm" />
                        <div className="block-can--text">{LOCALE.blockCan.crmSystems[LANG]}</div>
                    </div>
                    <div className="block-can--item">
                        <div className="block-can--icon icon--landing" />
                        <div className="block-can--text">{LOCALE.blockCan.landings[LANG]}</div>
                    </div>
                    <div className="block-can--item">
                        <div className="block-can--icon icon--mobapps" />
                        <div className="block-can--text">
                            {LOCALE.blockCan.mobileApps[LANG]}
                            <br />
                            {LOCALE.blockCan.mobileApps2[LANG]}
                        </div>
                    </div>
                    <div className="block-can--item">
                        <div className="block-can--icon icon--vkapps" />
                        <div className="block-can--text">{LOCALE.blockCan.vkMiniApps[LANG]}</div>
                    </div>
                    <div className="block-can--item">
                        <div className="block-can--icon icon--webapps" />
                        <div className="block-can--text">{LOCALE.blockCan.webApps[LANG]}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlockCan;
