import React from 'react';
import { LANG, LOCALE } from '../services/locales';
import BlockDoneItemList from './BlockDoneItemList';
import '../../styles/block-done.scss';

const BlockDone = () => {
    return (
        <div className="block-done">
            <div className="container">
                <div className="block-head">
                    <h1 className="block-title">{LOCALE.blockDone.alreadyCompleted[LANG]}</h1>
                    <div className="block-subtitle">
                        {LOCALE.blockDone.bestProjects[LANG]}
                        <span />
                    </div>
                </div>
                <BlockDoneItemList />
            </div>
        </div>
    );
};

export default BlockDone;
