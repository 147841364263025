import React from 'react';
import PropTypes from 'prop-types';

const Parallax = ({ top, left, right, image, size }) => {
    return (
        <div
            className="parallax"
            style={{
                backgroundImage: `url('${image}')`,
                width: `${size}px`,
                height: `${size}px`,
                top: `${top}px`,
                left: `${left}px`,
                right: `${right}px`,
            }}
        />
    );
};

Parallax.propTypes = {
    top: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
    image: PropTypes.string,
    size: PropTypes.number,
};

export default Parallax;
