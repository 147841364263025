import React from 'react';
import PropTypes from 'prop-types';

const BlockDoneItem = ({ onClick, image, name }) => {
    return (
        <div className="block-done--item" onClick={onClick}>
            <div
                className="block-done--image"
                style={{
                    backgroundImage: `url('${image}')`,
                }}
            />
            <div className="block-done--name">{name}</div>
        </div>
    );
};

BlockDoneItem.propTypes = {
    onClick: PropTypes.func,
    image: PropTypes.string,
    name: PropTypes.string,
};

export default BlockDoneItem;
