import React, { useState } from 'react';
import { LANG, LOCALE } from '../services/locales';
import OneReview from './OneReview';
import '../../styles/block-reviews.scss';

const BlockReviews = () => {
    const [currentReviewNum, setCurrentReviewNum] = useState(0);
    const [prevReviewNum, setPrevReviewNum] = useState(0);
    const [prevReview, setPrevReview] = useState(null);
    const [currentHeight, setCurrentHeight] = useState(0);
    const [currentTextHeight, setCurrentTextHeight] = useState(0);

    const maxReviewNum = LOCALE.blockReviewsItemList.reviews.length - 1;

    const getCurrentReview = () => LOCALE.blockReviewsItemList.reviews[currentReviewNum];

    const clickNext = () => {
        setPrevReviewNum(currentReviewNum);
        setPrevReview(getCurrentReview());

        if (currentReviewNum >= maxReviewNum) {
            setCurrentReviewNum(0);
        } else {
            setCurrentReviewNum(current => current + 1);
        }
    };

    const clickPrev = () => {
        setPrevReviewNum(currentReviewNum);
        setPrevReview(getCurrentReview());

        if (currentReviewNum <= 0) {
            setCurrentReviewNum(maxReviewNum);
        } else {
            setCurrentReviewNum(current => current - 1);
        }
    };

    const setHeights = (height, textHeight) => {
        setCurrentHeight(height);
        setCurrentTextHeight(textHeight);
    };

    const renderCurrentReview = () => (
        <OneReview
            key={currentReviewNum}
            emoji={getCurrentReview().emoji[LANG]}
            company={getCurrentReview().company[LANG]}
            author={getCurrentReview().author}
            text={getCurrentReview().description[LANG]}
            originalLink={getCurrentReview().letter[LANG]}
            heightChangeFunc={setHeights}
            prevClickFunc={clickPrev}
            nextClickFunc={clickNext}
        />
    );

    const renderPrevReview = () => (
        <OneReview
            key={prevReviewNum}
            emoji={prevReview.emoji[LANG]}
            company={prevReview.company[LANG]}
            author={prevReview.author}
            text={prevReview.description[LANG]}
            textHeight={currentTextHeight}
            originalLink={prevReview.letter[LANG]}
            prevClickFunc={clickPrev}
            nextClickFunc={clickNext}
            isSecondary
        />
    );

    return (
        <div className="block-reviews">
            <div className="block-reviews--bg-1" />
            <div className="block-reviews--bg-2" />
            <div className="container">
                <h1 className="block-title">{LOCALE.blockReviews.title[LANG]}</h1>
                <div className="block-reviews--review-block" style={{ height: `${currentHeight + 50}px` }}>
                    {prevReview && <div className="block-reviews--prev-review-block">{renderPrevReview()}</div>}
                    {renderCurrentReview()}
                </div>
            </div>
        </div>
    );
};

export default BlockReviews;
