import React from 'react';
import BlockLittleItem from './BlockLittleItem';
import {LANG, LOCALE} from "../services/locales";

const BlockLittleItemList = () => {
    const renderList = () => {
        return (
            <>
                <BlockLittleItem number={124} text={LOCALE.blockLittleItemList.textAllProjects[LANG]} />
                <BlockLittleItem number={6435} text={LOCALE.blockLittleItemList.textCoffee[LANG]} />
                <BlockLittleItem number={787128} text={LOCALE.blockLittleItemList.textCodeLines[LANG]} />
                <BlockLittleItem number={2528779} text={LOCALE.blockLittleItemList.textPurchases[LANG]} />
            </>
        );
    };
    return <div className="block-little--box">{renderList()}</div>;
};

export default BlockLittleItemList;
